

html, #root {
  height: 100%;
}

body {
  /* background: #93A9B6; */
  font: 14px/20px sans-serif;
  padding: 0;
  color: white;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: white;
}

a:visited {
  color: whitesmoke;
}

.container {
  margin: 0 auto;
  width: 100%;
  background: #93A9B6;
  height: 100%;
}

ol, ul {
    list-style: none;
    padding-inline-start: 4px;
}

.chat {
  width: 100%;
  float: left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #F2F5F8;
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  flex-flow: column;
}
.chat .chat-history {
  padding: 0px 10px 20px 0px;
  border-bottom: 1px solid black;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
}
.chat .chat-history .message-data {
  margin-bottom: 10px;
}
.chat .chat-history .message-data-time {
  color: #eeeeee;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 10px 15px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 20px;
  width: 88%;
  position: relative;
  white-space: pre-wrap;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #7E888C;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #7E888C;
  
}
.chat .chat-history .other-message {
  background: #2D3D53;
  margin-left: 18px;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #2D3D53;
  left: 93%;
}
.chat .chat-message {
  position: relative;
  padding: 10px;
  height: 70px;
}
.chat .chat-message textarea {
  width: 80%;
  border: none;
  padding: 10px 20px 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}

.chat .chat-message input {
  width: 100%;
  border: none;
  padding: 10px 0px 10px 10px;
  font: 16px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}

.chat .chat-message button {
  float: right;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #2D3D53;
  padding: 10px;
  border-radius: 30%;
}

.chat .chat-message span {
  display: block;
  overflow: hidden;
  padding: 0 1em;
}

.chat .chat-message button:hover {
  color: #93A9B6;
}

.chat .message img {
  max-width: 500px;
  width: 100%;
}

.chat .new-chat {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #848484;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat .new-chat Button {
  position: relative;
  float: left;
  
  color: #444753;
  font-size: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #9ad184;
  border-radius: 5px;
}

.chat .new-chat .msgNo {
  position: relative;
  left: -5px;
  top: -18px;
  background-color: red;
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat .infobox-parent {
  padding: 10px 0px 10px 0px;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 25%;
  background-color: #848484;
  z-index: 9999;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  border: 1px solid silver;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.chat .infobox {
  font-size: 16px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px !important;
}

.chat .infobox Button {
  width: 75%;
  color: white;
  font-size: 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #2D3D53;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.chat .end-message {
  position: relative;
  left: 5%;
  
  width: 85%;
  padding: 15px 5px;
  background-color: #2D3D53;
  
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  border: 1px solid silver;
}

.chat .end-message a {
  color: silver;
}

.chat .end-message .header {
  color: white;
}

.chat .overlay {
  backdrop-filter: blur(6px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86BB71;
}

.offline {
  color: #E38968;
}

.me {
  color: #94C2ED;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  /* height: 0; */
}


/* Typing indicator */

.typing-indicator {
  width: auto;
  padding: 10px;
  display: table;
  margin-left: 30px;
  position: relative;
}

.typing-text {
  margin-right: 10px;
}

.typing-dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-left: 4px;
  display: inline-block;
  background-color: #cccccc;
  animation: 1.2s typing-dot ease-in-out infinite;
}
.typing-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.typing-dot:nth-of-type(3) {
  animation-delay: 0.25s;
}
@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}

