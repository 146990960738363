/* .gameMenu {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 9999;
  }
  
  .gameMenu .showButton {
    height: 40px;
    line-height: 40px;  
    width: 40px;  
    font-size: 1.4em;
    font-weight: bold;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    
    color:black;
    text-align: center;
    cursor: pointer;
    
  }
  
  .gameMenu .menu {
    position: absolute;
    display: block;
    
    right: 0px;
  }

  .gameMenu .menu button {
    background-color: #9ad184;
    color: #444753;
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 2px;
    width: 100%;
    height: 50px;
  } */


/* Position and sizing of burger button */

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 36px;
    right: 15px;
    top: 5px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
}

.bm-burger-button span {
    position: absolute;
    width: 60%;
    height: 18px;
    left: 12px;
    top: 10px;
}


/* Color/shape of burger icon bars */

.bm-burger-bars {
    background: #373a47;
    height: 10% !important;
    border-radius: 5px;
}


/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
    background: #9ad184;
}


/* Position and sizing of clickable cross button */

.bm-cross-button {
    height: 24px;
    width: 24px;
}


/* Color/shape of close button cross */

.bm-cross {
    background: #bdc3c7;
}


/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}


/* General sidebar styles */

.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
}

.bm-menu a {
    color: #b8b7ad;
}

.bm-menu a:hover,
.bm-menu a:focus {
    color: #9ad184;
}

.bm-menu .menu-item {
    cursor: pointer;
}

.bm-item-list a {
    padding: 0.6em;
}

.bm-item-list a span {
    margin-left: 10px;
    font-weight: 700;
}


/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
    fill: #373a47;
}


/* Wrapper for item list */

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item-list .menu-header {
    margin-top: 20px;
    font-size: 0.8em !important;
    font-weight: bold;
}

/* Individual item */

.bm-item {
    display: inline-block;
}


/* Styling of overlay */

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}